if (!window.console){ window.console = { log:function(msg){ }}; }
$ = jQuery;
// @codekit-prepend jquery.colorbox-min.js;
(function(b,r,ea){function c(a,f,c){a=r.createElement(a);f&&(a.id=q+f);c&&(a.style.cssText=c);return b(a)}function M(a){var b=j.length;a=(p+a)%b;return 0>a?b+a:a}function n(a,b){return Math.round((/%/.test(a)?("x"===b?m.width():m.height())/100:1)*parseInt(a,10))}function fa(b){return a.photo||/\.(gif|png|jp(e|g|eg)|bmp|ico)((#|\?).*)?$/i.test(b)}function ga(){var B,f=b.data(k,v);null==f?(a=b.extend({},R),console&&console.log&&console.log("Error: cboxElement missing settings object")):a=b.extend({},
f);for(B in a)b.isFunction(a[B])&&"on"!==B.slice(0,2)&&(a[B]=a[B].call(k));a.rel=a.rel||k.rel||b(k).data("rel")||"nofollow";a.href=a.href||b(k).attr("href");a.title=a.title||k.title;"string"===typeof a.href&&(a.href=b.trim(a.href))}function D(a,f){b(r).trigger(a);b("*",h).trigger(a);f&&f.call(k)}function ma(){var b,f=q+"Slideshow_",c="click."+q,e,t;a.slideshow&&j[1]?(e=function(){G.html(a.slideshowStop).unbind(c).bind(S,function(){if(a.loop||j[p+1])b=setTimeout(g.next,a.slideshowSpeed)}).bind(T,function(){clearTimeout(b)}).one(c+
" "+U,t);h.removeClass(f+"off").addClass(f+"on");b=setTimeout(g.next,a.slideshowSpeed)},t=function(){clearTimeout(b);G.html(a.slideshowStart).unbind([S,T,U,c].join(" ")).one(c,function(){g.next();e()});h.removeClass(f+"on").addClass(f+"off")},a.slideshowAuto?e():t()):h.removeClass(f+"off "+f+"on")}function ha(c){N||(k=c,ga(),j=b(k),p=0,"nofollow"!==a.rel&&(j=b("."+F).filter(function(){var c=b.data(this,v),B;c&&(B=b(this).data("rel")||c.rel||this.rel);return B===a.rel}),p=j.index(k),-1===p&&(j=j.add(k),
p=j.length-1)),w||(w=H=!0,h.show(),a.returnFocus&&(b(k).blur(),b(r).one(ia,function(){b(k).focus()})),C.css({opacity:+a.opacity,cursor:a.overlayClose?"pointer":"auto"}).show(),a.w=n(a.initialWidth,"x"),a.h=n(a.initialHeight,"y"),g.position(),O&&m.bind("resize."+P+" scroll."+P,function(){C.css({width:m.width(),height:m.height(),top:m.scrollTop(),left:m.scrollLeft()})}).trigger("resize."+P),D(ja,a.onOpen),V.add(W).hide(),X.html(a.close).show()),g.load(!0))}function ka(){!h&&r.body&&(Y=!1,m=b(ea),h=
c(e).attr({id:v,"class":I?q+(O?"IE6":"IE"):""}).hide(),C=c(e,"Overlay",O?"position:absolute":"").hide(),Z=c(e,"LoadingOverlay").add(c(e,"LoadingGraphic")),E=c(e,"Wrapper"),u=c(e,"Content").append(l=c(e,"LoadedContent","width:0; height:0; overflow:hidden"),W=c(e,"Title"),$=c(e,"Current"),J=c(e,"Next"),K=c(e,"Previous"),G=c(e,"Slideshow").bind(ja,ma),X=c(e,"Close")),E.append(c(e).append(c(e,"TopLeft"),aa=c(e,"TopCenter"),c(e,"TopRight")),c(e,!1,"clear:left").append(ba=c(e,"MiddleLeft"),u,ca=c(e,"MiddleRight")),
c(e,!1,"clear:left").append(c(e,"BottomLeft"),da=c(e,"BottomCenter"),c(e,"BottomRight"))).find("div div").css({"float":"left"}),L=c(e,!1,"position:absolute; width:9999px; visibility:hidden; display:none"),V=J.add(K).add($).add(G),b(r.body).append(C,h.append(E,L)))}var R={transition:"elastic",speed:300,width:!1,initialWidth:"600",innerWidth:!1,maxWidth:!1,height:!1,initialHeight:"450",innerHeight:!1,maxHeight:!1,scalePhotos:!0,scrolling:!0,inline:!1,html:!1,iframe:!1,fastIframe:!0,photo:!1,href:!1,
title:!1,rel:!1,opacity:0.9,preloading:!0,current:"image {current} of {total}",previous:"previous",next:"next",close:"close",xhrError:"This content failed to load.",imgError:"This image failed to load.",open:!1,returnFocus:!0,reposition:!0,loop:!0,slideshow:!1,slideshowAuto:!0,slideshowSpeed:2500,slideshowStart:"start slideshow",slideshowStop:"stop slideshow",onOpen:!1,onLoad:!1,onComplete:!1,onCleanup:!1,onClosed:!1,overlayClose:!0,escKey:!0,arrowKey:!0,top:!1,bottom:!1,left:!1,right:!1,fixed:!1,
data:void 0},v="colorbox",q="cbox",F=q+"Element",ja=q+"_open",T=q+"_load",S=q+"_complete",U=q+"_cleanup",ia=q+"_closed",Q=q+"_purge",I=!b.support.leadingWhitespace,O=I&&!ea.XMLHttpRequest,P=q+"_IE6",C,h,E,u,aa,ba,ca,da,j,m,l,L,Z,W,$,G,J,K,X,V,a,x,y,z,A,k,p,d,w,H,N,la,g,e="div",Y;b.colorbox||(b(ka),g=b.fn[v]=b[v]=function(c,e){var d=this;c=c||{};ka();var s;h?(Y||(Y=!0,x=aa.height()+da.height()+u.outerHeight(!0)-u.height(),y=ba.width()+ca.width()+u.outerWidth(!0)-u.width(),z=l.outerHeight(!0),A=l.outerWidth(!0),
J.click(function(){g.next()}),K.click(function(){g.prev()}),X.click(function(){g.close()}),C.click(function(){a.overlayClose&&g.close()}),b(r).bind("keydown."+q,function(b){var c=b.keyCode;w&&(a.escKey&&27===c)&&(b.preventDefault(),g.close());w&&(a.arrowKey&&j[1])&&(37===c?(b.preventDefault(),K.click()):39===c&&(b.preventDefault(),J.click()))}),b(r).delegate("."+F,"click",function(a){1<a.which||(a.shiftKey||a.altKey||a.metaKey)||(a.preventDefault(),ha(this))})),s=!0):s=!1;if(s){if(!d[0]){if(d.selector)return d;
d=b("<a/>");c.open=!0}e&&(c.onComplete=e);d.each(function(){b.data(this,v,b.extend({},b.data(this,v)||R,c))}).addClass(F);(b.isFunction(c.open)&&c.open.call(d)||c.open)&&ha(d[0])}return d},g.position=function(b,c){function e(a){aa[0].style.width=da[0].style.width=u[0].style.width=parseInt(a.style.width,10)-y+"px";u[0].style.height=ba[0].style.height=ca[0].style.height=parseInt(a.style.height,10)-x+"px"}var d,j=d=0,l=h.offset(),p,k;m.unbind("resize."+q);h.css({top:-9E4,left:-9E4});p=m.scrollTop();
k=m.scrollLeft();a.fixed&&!O?(l.top-=p,l.left-=k,h.css({position:"fixed"})):(d=p,j=k,h.css({position:"absolute"}));j=!1!==a.right?j+Math.max(m.width()-a.w-A-y-n(a.right,"x"),0):!1!==a.left?j+n(a.left,"x"):j+Math.round(Math.max(m.width()-a.w-A-y,0)/2);d=!1!==a.bottom?d+Math.max(m.height()-a.h-z-x-n(a.bottom,"y"),0):!1!==a.top?d+n(a.top,"y"):d+Math.round(Math.max(m.height()-a.h-z-x,0)/2);h.css({top:l.top,left:l.left});b=h.width()===a.w+A&&h.height()===a.h+z?0:b||0;E[0].style.width=E[0].style.height=
"9999px";d={width:a.w+A+y,height:a.h+z+x,top:d,left:j};0===b&&h.css(d);h.dequeue().animate(d,{duration:b,complete:function(){e(this);H=!1;E[0].style.width=a.w+A+y+"px";E[0].style.height=a.h+z+x+"px";a.reposition&&setTimeout(function(){m.bind("resize."+q,g.position)},1);c&&c()},step:function(){e(this)}})},g.resize=function(b){w&&(b=b||{},b.width&&(a.w=n(b.width,"x")-A-y),b.innerWidth&&(a.w=n(b.innerWidth,"x")),l.css({width:a.w}),b.height&&(a.h=n(b.height,"y")-z-x),b.innerHeight&&(a.h=n(b.innerHeight,
"y")),!b.innerHeight&&!b.height&&(l.css({height:"auto"}),a.h=l.height()),l.css({height:a.h}),g.position("none"===a.transition?0:a.speed))},g.prep=function(k){function f(){a.w=a.w||l.width();a.w=a.mw&&a.mw<a.w?a.mw:a.w;return a.w}function m(){a.h=a.h||l.height();a.h=a.mh&&a.mh<a.h?a.mh:a.h;return a.h}if(w){var n,t="none"===a.transition?0:a.speed;l.remove();l=c(e,"LoadedContent").append(k);l.hide().appendTo(L.show()).css({width:f(),overflow:a.scrolling?"auto":"hidden"}).css({height:m()}).prependTo(u);
L.hide();b(d).css({"float":"none"});n=function(){function e(){I&&h[0].style.removeAttribute("filter")}var g=j.length,f,k;w&&(k=function(){clearTimeout(la);Z.detach().hide();D(S,a.onComplete)},I&&d&&l.fadeIn(100),W.html(a.title).add(l).show(),1<g?("string"===typeof a.current&&$.html(a.current.replace("{current}",p+1).replace("{total}",g)).show(),J[a.loop||p<g-1?"show":"hide"]().html(a.next),K[a.loop||p?"show":"hide"]().html(a.previous),a.slideshow&&G.show(),a.preloading&&b.each([M(-1),M(1)],function(){var a,
c;c=j[this];(a=b.data(c,v))&&a.href?(a=a.href,b.isFunction(a)&&(a=a.call(c))):a=c.href;fa(a)&&(c=new Image,c.src=a)})):V.hide(),a.iframe?(f=c("iframe")[0],"frameBorder"in f&&(f.frameBorder=0),"allowTransparency"in f&&(f.allowTransparency="true"),a.scrolling||(f.scrolling="no"),b(f).attr({src:a.href,name:(new Date).getTime(),"class":q+"Iframe",allowFullScreen:!0,webkitAllowFullScreen:!0,mozallowfullscreen:!0}).one("load",k).appendTo(l),b(r).one(Q,function(){f.src="//about:blank"}),a.fastIframe&&b(f).trigger("load")):
k(),"fade"===a.transition?h.fadeTo(t,1,e):e())};"fade"===a.transition?h.fadeTo(t,0,function(){g.position(0,n)}):g.position(t,n)}},g.load=function(h){var f,m,s=g.prep,t;H=!0;d=!1;k=j[p];h||ga();D(Q);D(T,a.onLoad);a.h=a.height?n(a.height,"y")-z-x:a.innerHeight&&n(a.innerHeight,"y");a.w=a.width?n(a.width,"x")-A-y:a.innerWidth&&n(a.innerWidth,"x");a.mw=a.w;a.mh=a.h;a.maxWidth&&(a.mw=n(a.maxWidth,"x")-A-y,a.mw=a.w&&a.w<a.mw?a.w:a.mw);a.maxHeight&&(a.mh=n(a.maxHeight,"y")-z-x,a.mh=a.h&&a.h<a.mh?a.h:a.mh);
f=a.href;la=setTimeout(function(){Z.show().appendTo(u)},100);a.inline?(t=c(e).hide().insertBefore(b(f)[0]),b(r).one(Q,function(){t.replaceWith(l.children())}),s(b(f))):a.iframe?s(" "):a.html?s(a.html):fa(f)?(b(d=new Image).addClass(q+"Photo").bind("error",function(){a.title=!1;s(c(e,"Error").html(a.imgError))}).load(function(){var b;d.onload=null;a.scalePhotos&&(m=function(){d.height-=d.height*b;d.width-=d.width*b},a.mw&&d.width>a.mw&&(b=(d.width-a.mw)/d.width,m()),a.mh&&d.height>a.mh&&(b=(d.height-
a.mh)/d.height,m()));a.h&&(d.style.marginTop=Math.max(a.h-d.height,0)/2+"px");if(j[1]&&(a.loop||j[p+1]))d.style.cursor="pointer",d.onclick=function(){g.next()};I&&(d.style.msInterpolationMode="bicubic");setTimeout(function(){s(d)},1)}),setTimeout(function(){d.src=f},1)):f&&L.load(f,a.data,function(d,f){s("error"===f?c(e,"Error").html(a.xhrError):b(this).contents())})},g.next=function(){if(!H&&j[1]&&(a.loop||j[p+1]))p=M(1),g.load()},g.prev=function(){if(!H&&j[1]&&(a.loop||p))p=M(-1),g.load()},g.close=
function(){w&&!N&&(N=!0,w=!1,D(U,a.onCleanup),m.unbind("."+q+" ."+P),C.fadeTo(200,0),h.stop().fadeTo(300,0,function(){h.add(C).css({opacity:1,cursor:"auto"}).hide();D(Q);l.remove();setTimeout(function(){N=!1;D(ia,a.onClosed)},1)}))},g.remove=function(){b([]).add(h).add(C).remove();h=null;b("."+F).removeData(v).removeClass(F);b(r).undelegate("."+F)},g.element=function(){return b(k)},g.settings=R)})(jQuery,document,window);
// @codekit-prepend jquery.easing.1.3.js;
/*
 * jQuery Easing v1.3 - http://gsgd.co.uk/sandbox/jquery/easing/
 *
 * Uses the built in easing capabilities added In jQuery 1.1
 * to offer multiple easing options
 *
 * TERMS OF USE - jQuery Easing
 *
 * Open source under the BSD License.
 *
 * Copyright © 2008 George McGinley Smith
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without modification,
 * are permitted provided that the following conditions are met:
 *
 * Redistributions of source code must retain the above copyright notice, this list of
 * conditions and the following disclaimer.
 * Redistributions in binary form must reproduce the above copyright notice, this list
 * of conditions and the following disclaimer in the documentation and/or other materials
 * provided with the distribution.
 *
 * Neither the name of the author nor the names of contributors may be used to endorse
 * or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY
 * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
 *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
 *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
 *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED
 * OF THE POSSIBILITY OF SUCH DAMAGE.
 *
*/

// t: current time, b: begInnIng value, c: change In value, d: duration
jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend( jQuery.easing,
{
	def: 'easeOutQuad',
	swing: function (x, t, b, c, d) {
		//alert(jQuery.easing.default);
		return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
	},
	easeInQuad: function (x, t, b, c, d) {
		return c*(t/=d)*t + b;
	},
	easeOutQuad: function (x, t, b, c, d) {
		return -c *(t/=d)*(t-2) + b;
	},
	easeInOutQuad: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t + b;
		return -c/2 * ((--t)*(t-2) - 1) + b;
	},
	easeInCubic: function (x, t, b, c, d) {
		return c*(t/=d)*t*t + b;
	},
	easeOutCubic: function (x, t, b, c, d) {
		return c*((t=t/d-1)*t*t + 1) + b;
	},
	easeInOutCubic: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t + b;
		return c/2*((t-=2)*t*t + 2) + b;
	},
	easeInQuart: function (x, t, b, c, d) {
		return c*(t/=d)*t*t*t + b;
	},
	easeOutQuart: function (x, t, b, c, d) {
		return -c * ((t=t/d-1)*t*t*t - 1) + b;
	},
	easeInOutQuart: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
		return -c/2 * ((t-=2)*t*t*t - 2) + b;
	},
	easeInQuint: function (x, t, b, c, d) {
		return c*(t/=d)*t*t*t*t + b;
	},
	easeOutQuint: function (x, t, b, c, d) {
		return c*((t=t/d-1)*t*t*t*t + 1) + b;
	},
	easeInOutQuint: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
		return c/2*((t-=2)*t*t*t*t + 2) + b;
	},
	easeInSine: function (x, t, b, c, d) {
		return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
	},
	easeOutSine: function (x, t, b, c, d) {
		return c * Math.sin(t/d * (Math.PI/2)) + b;
	},
	easeInOutSine: function (x, t, b, c, d) {
		return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
	},
	easeInExpo: function (x, t, b, c, d) {
		return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
	},
	easeOutExpo: function (x, t, b, c, d) {
		return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
	},
	easeInOutExpo: function (x, t, b, c, d) {
		if (t==0) return b;
		if (t==d) return b+c;
		if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	},
	easeInCirc: function (x, t, b, c, d) {
		return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
	},
	easeOutCirc: function (x, t, b, c, d) {
		return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
	},
	easeInOutCirc: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
		return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
	},
	easeInElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
	},
	easeOutElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
	},
	easeInOutElastic: function (x, t, b, c, d) {
		var s=1.70158;var p=0;var a=c;
		if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
		if (a < Math.abs(c)) { a=c; var s=p/4; }
		else var s = p/(2*Math.PI) * Math.asin (c/a);
		if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
		return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
	},
	easeInBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		return c*(t/=d)*t*((s+1)*t - s) + b;
	},
	easeOutBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
	},
	easeInOutBack: function (x, t, b, c, d, s) {
		if (s == undefined) s = 1.70158;
		if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
		return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
	},
	easeInBounce: function (x, t, b, c, d) {
		return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
	},
	easeOutBounce: function (x, t, b, c, d) {
		if ((t/=d) < (1/2.75)) {
			return c*(7.5625*t*t) + b;
		} else if (t < (2/2.75)) {
			return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
		} else if (t < (2.5/2.75)) {
			return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
		} else {
			return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
		}
	},
	easeInOutBounce: function (x, t, b, c, d) {
		if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
		return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
	}
});

/*
 *
 * TERMS OF USE - EASING EQUATIONS
 *
 * Open source under the BSD License.
 *
 * Copyright © 2001 Robert Penner
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without modification,
 * are permitted provided that the following conditions are met:
 *
 * Redistributions of source code must retain the above copyright notice, this list of
 * conditions and the following disclaimer.
 * Redistributions in binary form must reproduce the above copyright notice, this list
 * of conditions and the following disclaimer in the documentation and/or other materials
 * provided with the distribution.
 *
 * Neither the name of the author nor the names of contributors may be used to endorse
 * or promote products derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY
 * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
 *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
 *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
 *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED
 * OF THE POSSIBILITY OF SUCH DAMAGE.
 *
 */
// @codekit-prepend ppt.jquery.utility.js;
if (!window.console){ window.console = { log:function(msg){ }}; }

//新規ウィンドウをオープンさせる
$.fn.blankWindow　=　function(className){
	var className = className || 'externalLink';
	$(this).addClass(className).on('click',function(){
		window.open(this.href, '_blank');
		return false;
	});
}

//ページの先頭へアニメーションスクロール
$.fn.toTop=function(){
	var $btn = $(this),isHidden = true;
	$(window).scroll(function () {
		if( $(this).scrollTop() > 50 ) {
			if( isHidden ) {
				$btn.stop(true,true).fadeIn();
				isHidden = false;
			}
		} else {
			if( !isHidden ) {
				$btn.stop(true,true).fadeOut();
				isHidden = true;
			}
		}
	});
	$btn.hide().click(function(){ $('html, body').animate({'scrollTop': 0 }, 600, 'easeInOutExpo'); return false; });
}

//ウィンドウイベントの初回登録
//addWindowEvent(e.type,'scroll');
$.fn.addWindowEvent = function(e,event){
	if(e['ppt_isAddEvent']) return;
	var fn = function(){ $(e.target).trigger(e); };
	if(!event) return;
	else if(event == 'resize') $(window).resize(fn);
	else if(event == 'scroll') $(window).scroll(fn);
	e['ppt_isAddEvent'] = true;
}

//段組の高さ揃え flatColumn
// col:int 行単位で揃える。数値は列。
$.fn.flatColumn = function(col){
	var col = col || 0;
	var line = $(this).length;
	line = col ? Math.floor(line / col) : line;
	var i = col==0 ? line : 0;
	while(i<=line){
		var maxHeight = 0;
		var $target = col==0 ? $(this) : $(this).slice((i)*col, (i+1)*col);
		$target.each(function(index, element) {
			var height = $(this).height();
			if(maxHeight<height) maxHeight = height;
		});
		$target.height(maxHeight).css('padding-bottom','5px');
		i++;
	}
	return this;
}







// main
//
$(function(){
	$.support.transform  = typeof $("body").css("transform") === "string";
	$.support.transition = typeof $("body").css("transitionProperty") === "string";

	var page = {
		id 		: document.getElementsByTagName('body')[0].getAttribute('id'),
		classes	: false//document.getElementsByTagName('body')[0].getAttribute('class').split(' ')
	};

	//擬似スクロール化 for touchdevice
	var scCount = 0;
	$('html').get(0).addEventListener('touchmove', function(event) {
		scCount++;
		if(scCount>60){
	    	$('#body').trigger('hueChange');
			scCount = 0;
		}
    });

	//新規ウィンドウ
	var noBlank	= [
		'[href^="http://dorp.jp/"]',
		'[href^="https://dorp.jp/"]',
		'[href^="http://www.dorp.jp/"]',
		'[href^="https://www.dorp.jp/"]',
		'[href^="http://test.dorp.jp/"]'
	];
	$('a[href^="https://"],a[href^="http://"]:not('+noBlank+')').blankWindow();
	$('a[href$=".pdf"]').blankWindow();

	//toTop
	$('#toTop>a').toTop();

	//ページ内スクロール
	$('#sub a[href^="#"],#container-header a[href^="#"]').on('click',function(){
		$('html, body').animate({
				'scrollTop': $($(this).attr('href')).offset().top
			}, 600, 'easeInOutExpo');
		return false;
	});

	//jpg拡大
	$('a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".gif"]').each(function(index, element) {
		$(this).colorbox({
			maxWidth:'100%', maxHeight:'95%',
			transition:'fade',
			title:$(this).children('img').attr('alt'),
			rel:'gallery'
		});
	});

	//メニュー開閉
	$('#global-nav').each(function(index, element) {
		var $target = $(this);
		$(this).on('click',function(event){
			if($target.hasClass('open')){
				$target.removeClass('open');
			}else{
				$target.addClass('open');
				event.stopPropagation()
			}
		});
	});
	$('body').on('click',function(event){
		if($('#global-nav').hasClass('open')){
			$('#global-nav').removeClass('open');
		}
	});

	$('body')
		.each(function(index, el) {
			this.hue = 0;
			this.alpha = 1;
		})
		.on('hueChange',function(e){
			$(this).addWindowEvent(e,'scroll');
			this.hue+=5;
			this.hue = this.hue>360?0:this.hue;
			var color = 'hsla(' + this.hue + ', 100%, 90%, ' + this.alpha + ')';
			var textcolor = 'hsla(' + this.hue + ', 100%, 80%, ' + this.alpha + ')';
			$('.holo, #body, #container-header').css({borderColor:color});
			$('.holo-bg').css({backgroundColor:textcolor});
			$('.holo-txt, .totop>a').css({color:textcolor});
		})
		.on('fixedZoomFix',function(e){
			$(this).addWindowEvent(e,'resize');
			// console.log($('html').width());//414
			// console.log($('body').width());//366
			// console.log($(window).width());//414
			var width = $('html').width()-24;
			$('#container-footer').innerWidth(width);
		})
		.on('subHeightFix',function(e){
			$(this).addWindowEvent(e,'resize');
			var heightMain = $('#main').outerHeight() + $('#container-header').outerHeight();
			var heightSub = $('#sub').outerHeight();
			if(heightMain<heightSub){
				$('#sub').outerHeight(heightMain);
			}
			$('#container').css({minHeight:$(window).innerHeight()});
		})
		.trigger('hueChange')
		.trigger('fixedZoomFix');
		//.trigger('subHeightFix');
})
